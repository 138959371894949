
// Write your custom style. Feel free to split your code to several files

@import './prism'; // our custom prism theme

$starry-gradient: linear-gradient(-45deg, #4b7cd4 0%, #3b4c82 100%);

@mixin stars() {
  position: relative;
  background: $starry-gradient;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: 1100px 1100px, 700px 700px, 500px 500px, 300px 300px;
    background-position: 0 0, 80px 120px, 260px 540px, 140px 200px;
    background-attachment: fixed;
    background-repeat: repeat;
    background-image:
      radial-gradient(white, rgba(255,255,255,.2) 2px, transparent 4px),
      radial-gradient(white, rgba(255,255,255,.15) 1px, transparent 3px),
      radial-gradient(white, rgba(255,255,255,.1) 2px, transparent 4px),
      radial-gradient(rgba(255,255,255,.4), rgba(255,255,255,.1) 2px, transparent 3px);
    pointer-events: none;
  }
}

// Starry backgrounds
.bg-starry {
  @include stars();
}

// Browser mockup component
.browser-mockup {
  border-top: 40px solid rgba(230, 230, 230, 0.7);
  box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.4);
  position: relative;
  border-radius: 3px 3px 0 0;
  margin: 1.5rem 0;

  &::before {
    position: absolute;
    content: '';
    top: -24px;
    left: 16px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #ff5f58;
    box-shadow: 0 0 0 2px #ff5f58, 1.25em 0 0 2px #ffbf2e, 2.5em 0 0 2px #2acd43;
  }

  > img {
    display: block !important;
    width: 100% !important;
    height: auto !important;
    border-radius: 0 0 3px 3px !important;
    margin: 0 !important;
  }

  &.browser-mockup-sm {
    border-top-width: 30px;

    &::before {
      top: -18px;
      width: 6px;
      height: 6px;
    }
  }
}

// Make code blocks more subtle
pre {
  border: solid 1px darken($color-light, 2.5%);
  border-radius: 2px;
  text-align: left;
}

// Standard blockquotes
blockquote:not(.blockquote) {
  @extend .blockquote;
  margin: 48px 0;
}

// Make small weight match surrounding text
small {
  font-weight: inherit;
}

// Prevent the quote sign container from blocking clicks on the editable quote
.blockquote .quote-sign {
  pointer-events: none;
}

// Add a focus ring to inputs
.form-control:focus {
  border-color: $color-primary;
}

// Make the navbar logo a bit smaller
.navbar {
  .navbar-brand img {
    max-height: 40px;

    @include media-breakpoint-down(md) {
      max-width: 200px;
    }
  }
}

// Lighter weight on nav links
.navbar .nav-link {
  font-weight: 400;
}

footer .nav-link {
  @include media-breakpoint-down(sm) {
    padding: 0 1.5rem 0 0;
  }
}

// Make the navbar login button dark when scrolled
body.navbar-scrolled .navbar .btn-outline-light {
  @extend .btn-outline-dark;
}

// Colored backdrop for the open navbar on mobile
@include media-breakpoint-down(md) {
  .backdrop-navbar {
    background: $starry-gradient;//-color: $primary;
    opacity: .8;
  }
}

// Dark mobile nav
@include media-breakpoint-down(md) {
  .navbar .navbar-mobile {
    background-color: $gray-900;

    .nav-navbar {
      .nav-link,
      .nav-link.is-current {
        color: $gray-500;

        &:hover {
          color: $white;
        }
      }

      .dropdown,
      .dropdown:hover {
        .nav-link {
          color: $gray-500;

          .arrow {
            border-top-color: currentColor;
          }

          &:hover {
            color: $white;
          }
        }

        .nav {
          background-color: $gray-800;

          .nav-item {
            color: $gray-500;

            &:hover {
              color: $white;
            }
          }
        }
      }
    }
  }

  // Login button should always be light on mobile
  body.navbar-scrolled .navbar .navbar-mobile {
    .btn-outline-light {
      @include button-outline-variant($light);
    }
  }
}

// Highlight sidebar links
.sidebar .nav-link {
  &.is-current {
    background-color: $primary;
    border-radius: 2px;
    color: $white;
    padding-left: 8px;
    padding-right: 8px;
    transform: translateX(-8px);
    transition: .25s background-color, .25s color;
  }
}

// Make the homepage heading fit on mobile
@include media-breakpoint-down(sm) {
  .homepage-header h1 {
    font-size: 2rem;
  }

  .homepage-header p {
    display: inline;
  }
}

// Remove top border from footer
.footer {
  border-top: none;
}

// Main feature images
[id^="main-feature-image"] {
  display: block;
  width: 80%;
  margin: 0 auto;

  @include media-breakpoint-down(sm) {
    width: 60%;
  }
}

// Main footer logo size
.main-footer-logo {
  width: 25%;

  @include media-breakpoint-only(md) {
    width: 40%;
  }

  @include media-breakpoint-down(sm) {
    width: 50%;
  }
}

.pricing-plan {
  font-size: 1rem;
  margin-bottom: 2rem;

  small {
    font-size: .75rem;
  }
}

// Emphasize pricing columns
.pricing-emphasize {
  @include media-breakpoint-up(lg) {
    transform: scale(1.05);
  }
}

// Adjust price unit size
.price-unit {
  font-size: 32px;
  margin-right: -12px;
  margin-top: 12px;
}

// Make text full white
.text-white {
  .price-plan {
    color: white;
  }
}

// Custom form styles
.contact-form,
.signup-form {
  .form-control {
    background-position: center right calc(.475em + .1875rem);
  }

  textarea.form-control {
    line-height: 1.6;
  }

  .form-popup {
    max-width: 440px;
    border: none;
    border-left: solid 6px $color-danger;

    @include media-breakpoint-down(sm) {
      right: 2.5%;
      width: 95%;
    }

    .form-popup-message {
      margin-bottom: 0;
    }
  }
}

// Docs articles
.docs-article {
  h2,
  h3,
  h4,
  h5,
  h6 {
    &:not(:first-child) {
      margin-top: 1.5em;
    }
  }

  hr {
    @extend .hr-dash;
    margin: 48px auto;
  }

  // Standard image styles
  img {
    display: block;
    width: auto;
    height: auto;
    border-radius: 8px;
    max-width: 100%;
    margin: 32px auto;
  }

  figure.image {
    width: 80%;
    margin: 32px auto;

    img {
      width: 100%;
      margin: 0;
    }

    figcaption {
      text-align: center;
      font-size: $small-font-size;
      color: $gray-500;
      margin-top: 8px;
    }
  }

  // Centered image styles
  img.align-center {
    position: relative;
    width: 100%;
    margin: 32px auto;
  }

  figure.image.align-center {
    position: relative;
    width: 100%;
    margin: 32px auto;

    img {
      margin: 0;
    }
  }

  // Left-aligned images
  img.align-left,
  figure.image.align-left {
    float: left;
    width: calc(50% - 16px);
    margin: 16px 32px 16px 0;

    @include media-breakpoint-down(sm) {
      margin: 8px 16px 8px 0;
    }
  }

  // Right-aligned images
  img.align-right,
  figure.image.align-right {
    float: right;
    width: calc(50% - 16px);
    margin: 16px 0 16px 32px;

    @include media-breakpoint-down(sm) {
      margin: 8px 0 8px 16px;
    }
  }
}

// Blog index
.blog-index-post {
  img {
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 0;
    border-top-left-radius: .25rem;
    border-bottom-left-radius: .25rem;

    @include media-breakpoint-down(sm) {
      position: static;
      height: 200px;
      border-radius: 0;
      border-top-left-radius: .25rem;
      border-top-right-radius: .25rem;
    }

    @include media-breakpoint-down(xs) {
      height: 50vw;
    }
  }
}

.post-content {
  h2,
  h3,
  h4,
  h5,
  h6 {
    &:not(:first-child) {
      margin-top: 1.5em;
    }
  }

  hr {
    max-width: 100px;
    margin-top: 48px;
    margin-bottom: 48px;
  }

  // Standard image styles
  img {
    display: block;
    width: calc(100% + 128px);
    height: auto;
    border-radius: 8px;
    max-width: none;
    margin: 64px 0 64px -64px;

    @include media-breakpoint-down(md) {
      width: 100%;
      margin-left: 0;
    }
  }

  figure.image {
    width: calc(100% + 128px);
    margin: 64px 0 64px -64px;

    @include media-breakpoint-down(md) {
      width: 100%;
      margin-left: 0;
    }

    img {
      width: 100%;
      margin: 0;
    }

    figcaption {
      text-align: center;
      font-size: $small-font-size;
      color: $gray-500;
      margin-top: 8px;
    }
  }

  // Centered image styles
  img.align-center {
    position: relative;
    width: 100vw;
    border-radius: 0;
    min-width: 100%;
    margin: 32px calc(50% - 50vw) !important;
    transform: translateX(calc(50vw - 50%));
  }

  figure.image.align-center {
    position: relative;
    width: 100vw;
    border-radius: 0;
    min-width: 100%;
    margin: 32px calc(50% - 50vw) !important;
    transform: translateX(calc(50vw - 50%));

    img {
      margin: 0;
    }
  }

  // Left-aligned images
  img.align-left,
  figure.image.align-left {
    float: left;
    width: calc(50% - 16px);
    margin: 16px 32px 16px -32px;

    @include media-breakpoint-down(sm) {
      width: 45%;
      margin: 8px 16px 8px 0;
    }
  }

  // Right-aligned images
  img.align-right,
  figure.image.align-right {
    float: right;
    width: calc(50% - 16px);
    margin: 16px -32px 16px 32px;

    @include media-breakpoint-down(sm) {
      width: 45%;
      margin: 8px 0 8px 16px;
    }
  }
}

// Discourage high-level headings in blogs and documentation
.is-cms .post-content,
.is-cms .docs-article {
  h1,
  h2 {
    // Discourage use in the editor
    text-decoration: underline wavy;
    text-decoration-color: tomato;
  }
}

.elevator-pitch {
  display: flex;
  justify-content: center;
  align-items: flex-start;

  @include media-breakpoint-down(sm) {
    display: block;
  }

  .elevator-pitch-item {
    width: 30%;
    flex: 0 0 auto;
    text-align: center;
    padding: 2rem;

    @include media-breakpoint-down(sm) {
      width: auto;
    }

    img {
      display: block;
      width: 60%;
      height: auto;
      margin: 0 auto 1rem auto;

      @include media-breakpoint-down(sm) {
        width: 70%;
      }
    }
  }

  .elevator-pitch-arrow {
    display: flex;
    height: 100%;
    align-items: center;
    flex: 0 0 auto;
    padding: 2rem;

    @include media-breakpoint-down(sm) {
      display: block;
      height: auto;
      text-align: center;
      transform: rotate(90deg);
    }
  }
}

.editor-typing {
  position: relative;

  img:not(.editor-typing-animation) {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }

  .editor-typing-animation {
    position: absolute;
    top: 214px;
    left: 460px;
    width: 382px;

    @include media-breakpoint-only(lg) {
      top: 178px;
      left: 383px;
      width: 318px;
    }

    @include media-breakpoint-only(md) {
      top: 128px;
      left: 278px;
      width: 237px;
    }

    @include media-breakpoint-only(sm) {
      top: 114px;
      left: 246px;
      width: 205px;
    }


    @include media-breakpoint-only(xs) {
      top: 38.5%;
      left: 51.5%;
      width: 43%;
    }
  }
}
