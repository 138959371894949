code[class*=language-],
pre[class*=language-] {
  color: #000;
  text-align: left;
  white-space: pre;
  word-spacing: normal;
  word-break: normal;
  word-wrap: normal;
  tab-size: 2;
  hyphens: none
}

pre[class*=language-] {
  overflow: auto
}

:not(pre)>code[class*=language-] {
  white-space: normal
}

.token.cdata,
.token.comment,
.token.doctype,
.token.prolog {
  color: #a6adb2;
}

.token.operator,
.token.punctuation {
  color: black;
}

.namespace {
  opacity: .7
}

.token.number {
  color: #ed5c65;
}

.token.boolean,
.token.constant,
.token.deleted,
.token.property,
.token.symbol {
  color: #08d;
}

.token.tag {
  color: #08d;

  .punctuation {
    color: inherit;
  }
}

.token.attr-name,
.token.builtin,
.token.char,
.token.inserted {
  color: #dc7f13;
}

.token.selector,
.token.url {
  color: #08d;
}

.language-css .token.property {
  color: #ed5c65;
}

.token.rule {
  color: #f18c16;
}

.token.string {
  color: #269061;
}

.token.atrule {
  color: #f18c16;
}

.token.attr-value {
  color: #269061;
}

.token.class-name,
.token.keyword,
.token.function {
  color: #a151d2
}

.token.important,
.token.regex {
  color: #f50;
}

.token.entity {
  color: #9e76e6;
}

.token.variable {
  color: #2795ee;
}

.token.bold,
.token.important {
  font-weight: 700
}

.token.italic {
  font-style: italic
}

.token.entity {
  cursor: help
}
